// React
import React, { useState, useEffect, useRef } from "react";
// Firebase
import { httpsCallable } from "firebase/functions";
import { functions } from "../../services/firebase.config";
// Styles
import 'bootstrap/dist/css/bootstrap.css';
// Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GeneralDashboardLayout from "../Layouts/GeneralDashboardLayout";
import ThreeChartHorizontalDashboardLayout from '../Layouts/ThreeChartHorizontalDashboardLayout';
import FiveChartStackedLayout from '../Layouts/FiveChartStackedLayout';
import TitleCard from '../Cards/TitleCard';
import SingleMetricCard from '../Cards/SingleMetricCard';
import GeneralDashboardCard from '../Cards/GeneralDashboardCard';
import BarChart from '../Charts/BarChart';
import PercentStackedBarChart from "../Charts/PercentStackedBarChart";
import StackedBarChart from "../Charts/StackedBarChart";
import LineChart from '../Charts/LineChart';
import DoughnutChart from '../Charts/Doughnut';
import LoadingBar from "../Spinners/ProgressBar";
import TwoPeopleIcon from '../Icons/TwoPeopleIcon';
import TwoHousesIcon from '../Icons/TwoHousesIcon';
import PersonInBoxIcon from '../Icons/PersonInBoxIcon';
import Map from "../Map/Map";
import Footnote from "../Collapse/Footnote";
import HemsonLogo from '../Icons/HemsonLogo.js';
import GeneralFootnote from "../Collapse/GeneralFootnote.js";
// Helpers
import { 
  setStorage, 
  getStorage, 
  deepArrayComparison, 
  addCommas, 
  deepObjectComparison,
  createFilterTextObject
} from "./DashboardHelpers";
import { dashboardType, dashboardObject } from "../../configuration/dashboardNames.js";

/* -------------------- Firebase Callable Function Setup -------------------- */

const dataFetch = httpsCallable(functions, 'dataFetch');

/* -------------------------------------------------------------------------- */
/*                              DASHBOARD GRAPHS                              */
/* -------------------------------------------------------------------------- */

/**
 * The dashboard graphs component.
 * @component
 * @param {Object} props - The component accepts props.
 * @param {string} props.dashboard - Dashboard selection value.
 * @param {Objects[]} props.municipality - Municipality selection value.
 * @param {Objects[]} props.municipalities - Municipalities selection value.
 * @param {number[]} props.years - Array of start and end years.
 * @param {boolean} props.constant - Inflation selection value.
 * @param {boolean} props.footnote - Footnote selection value.
 * @param {boolean} props.dashboardLoading - Dashboard loading spinner state.
 * @param {boolean} props.theme - Theme selection value.
 * @param {function(boolean): void} props.handleDashboardLoading - Callback to handle dashboard loading spinner state change.
 * @param {function(boolean): void} props.handleGenerate - Callback to handle the data fetch state.
 * @param {object} props.filter - A custom filter object.
 * @returns The component containing dashboard graphs.
 */
const Dashboards = ({
  dashboard,
  municipality,
  municipalities,
  years,
  constant,
  footnote,
  theme,
  dashboardLoading,
  handleDashboardLoading,
  generate,
  handleGenerate,
  filter
}) => {

  const defaultData = {
    municipalityArray: [],
    data: [],
    years: years,
    constant: constant
  }

  const [data, setData] = useState(defaultData);
  const [progress, setProgress] = useState(0);
  const [dashboardError, setDashboardError] = useState(false);
  const [dashboardErrorMessage, setDashboardErrorMessage] = useState('');
  const prevPropsRef = useRef();

  /* -------------------------------------------------------------------------- */
  /*                                 DATA FETCH                                 */
  /* -------------------------------------------------------------------------- */

  /**
   * Function that handles when a user clicks the Generate button.
   * @param {boolean} boolean - State of generate button click. 
   */
  const dashboardGenerate = (boolean) => {
    handleGenerate(boolean);
  }

  /**
   * Functions that fetches chart data based on query.
   * @returns
   */
  const retrieveData = async () => {
    try {

      handleDashboardLoading(true);
      setProgress(0);

      const storageData = await getStorage(`${dashboard}Data`);
      const type = dashboardType(dashboard);
      const dashboardIsMulti = type === 'multi';
      const municipalityArray = dashboardIsMulti ? municipalities : municipality;
      
      if (
        storageData && 
        deepArrayComparison(storageData.municipalityArray, municipalityArray) &&
        deepArrayComparison(storageData.years, years) && 
        storageData.constant === constant &&
        deepObjectComparison(storageData.filter, filter) &&
        !generate
      ) {
        
        //console.log('Data is in local storage!');
        setData(storageData);
      
      } else {
        
        //console.log('Call to Azure needed!');
        let response = await dataFetch({
          dashboard: dashboard,
          municipality: municipalityArray,
          years: years,
          constant: constant,
          filter: filter
        });
        
        // Set and save data
        setData(response.data); // array
        //console.log(response.data)
        
        setStorage(`${dashboard}Data`, {
          ...response.data,
          filter: filter
        });
      
      }                    

      setDashboardError(false);

      setProgress(100);

    } catch (error) {

      // On error, clear the dashboard
      setDashboardError(true);
      setData(defaultData);
      setStorage(`${dashboard}Data`, defaultData);
      
      const code = error?.code || 'None';
      const message = error?.message || 'None';
      const details = error?.details || {};
      const detailedCode = details?.detailedCode || 'None';
      const detailedMessage = details?.detailedMessage || 'None';

      const errorString = 'Error in data fetch.\n' +
        `Code: ${code}\n` +
        `Message: ${message}\n` +
        `Detailed Code: ${detailedCode}\n` +
        `Detailed Message: ${detailedMessage}`;
      
      console.log(errorString);
      setDashboardErrorMessage(errorString);

      alert(errorString);

    } finally {

      handleDashboardLoading(false);
      dashboardGenerate(false);
      
    }
  };

  /**
   * Simulates a progress number count for use with progress bar or spinner.
   * @returns {number} A timer number.
   */
  const simulateProgress = () => {
    let intervalId;
    intervalId = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + 1;
        if (newProgress >= 100) {
          clearInterval(intervalId);
        }
        return newProgress;
      });
    }, 10);
  };

  useEffect(() => {
    const prevProps = prevPropsRef.current;

    // Always perform actions if 'generate' is true
    if (generate) {
      //console.log('Button Clicked!');
      simulateProgress();
      retrieveData();
    }

    // Perform actions on initial render
    if (!prevProps) {
      // Initial render actions
      //console.log('Initial Render!');
      simulateProgress();
      retrieveData();
    } else {
      // Check which prop triggered the useEffect
      if (prevProps.dashboard !== dashboard) {
        //console.log('Dashboard Changed!');
        simulateProgress();
        retrieveData();
      }
      
      if (prevProps.constant !== constant) {
        //console.log('Constant Changed!');
        simulateProgress();
        retrieveData();
      }
    }

    // Save the states
    prevPropsRef.current = { dashboard, constant };
  }, [generate, dashboard, constant]);

  
  /* -------------------------------------------------------------------------- */
  /*                    Set Parameters Depending on Dashboard                   */
  /* -------------------------------------------------------------------------- */

  /* ---------------------------- Titles and Names ---------------------------- */

  const defaultTitle = !dashboardError ? 'Choose municipalities to get started.' : 'An error occurred. Choose another municipality to continue...';
  
  const municipalityArray = data.data[0] ? data.data[0] : []; // Array includes the allDataAvailable key (boolean)

  // An array of formatted municipal names
  let formattedMunicipalName = municipalityArray.length > 0 && !dashboardError ? municipalityArray.map(element => element.name) : [defaultTitle];
  let filterText = false;

  // Years
  const singleYear = ` (${data.years[1]})`;
  const multiYear = ` (${data.years[0]} - ${data.years[1]})`;

  /* -------------------------- Demographic Box Data -------------------------- */
  
  let boxIndex = data.data.findIndex(element => element.id === 'demographicBox');
  
  let box1Title = data.data[boxIndex]?.dataArray[0].label || null;
  let box1Data = data.data[boxIndex]?.dataArray[0].values.AMOUNT || null;
  box1Data = box1Data ? addCommas(box1Data, false) : null;

  let box2Title = data.data[boxIndex]?.dataArray[1].label || null;
  let box2Data = data.data[boxIndex]?.dataArray[1].values.AMOUNT || null;
  box2Data = box2Data ? addCommas(box2Data, false) : null;

  let box3Title = data.data[boxIndex]?.dataArray[2].label || null;
  let box3Data = data.data[boxIndex]?.dataArray[2].values.AMOUNT || null;
  box3Data = box3Data ? addCommas(box3Data, true) : null;

  /* ------------------------------- Chart Data ------------------------------- */

  let chart1Index = data.data.findIndex(element => element.id === 'chart1');
  let chart1Title = data.data[chart1Index]?.title || null;
  let chart1Data = data.data[chart1Index]?.dataArray || null;
  let chart1Label = data.data[chart1Index]?.labelArray || null;

  let chart2Index = data.data.findIndex(element => element.id === 'chart2');
  let chart2Title = data.data[chart2Index]?.title || null;
  let chart2Data = data.data[chart2Index]?.dataArray || null;
  let chart2Label = data.data[chart2Index]?.labelArray || null;

  let chart3Index = data.data.findIndex(element => element.id === 'chart3');
  let chart3Title = data.data[chart3Index]?.title || null;
  let chart3Data = data.data[chart3Index]?.dataArray || null;
  let chart3Label = data.data[chart3Index]?.labelArray || null;

  let chart4Index = data.data.findIndex(element => element.id === 'chart4');
  let chart4Title = data.data[chart4Index]?.title || null;
  let chart4Data = data.data[chart4Index]?.dataArray || null;
  let chart4Label = data.data[chart4Index]?.labelArray || null;

  let chart5Index = data.data.findIndex(element => element.id === 'chart5');
  let chart5Title = data.data[chart5Index]?.title || null;
  let chart5Data = data.data[chart5Index]?.dataArray || null;
  let chart5Label = data.data[chart5Index]?.labelArray || null;


  // Check for missing Data
  let dataStatus = '';
  let dataAvailableForAllYears = municipalityArray.every(municipality => municipality.allDataAvailable);

  if (dataAvailableForAllYears && !dashboardError) {
    dataStatus = 'Data Available';
  } else if (!dataAvailableForAllYears && !dashboardError) {
    dataStatus = 'Missing Data';
  } else {
    dataStatus = 'Error'
  }

  /* -------------------------------- Footnotes ------------------------------- */

  const mapFootnote = dashboardError ? null: {
    name: 'Location Map',
    description: 'Shows the general location of the municipality in Ontario.',
    insight: 'The map is provided for contextual purposes.'
  }

  let chartFootnote1 = data.data[chart1Index]?.footnoteObject;
  let chartFootnote2 = data.data[chart2Index]?.footnoteObject;
  let chartFootnote3 = data.data[chart3Index]?.footnoteObject;
  let chartFootnote4 = data.data[chart4Index]?.footnoteObject;
  let chartFootnote5 = data.data[chart5Index]?.footnoteObject;


    
  let chartsToRender;

  /* ----------------------- Demographic Box Components ----------------------- */
  
  let PopulationBox = 
    <SingleMetricCard
      boxLabel={'Census Population'}
      boxData={box1Data ? box1Data + ' persons' : 'No Data'}
      footnoteText={box1Data ? `Based on ${data.years[1]}` : 'No Data'} 
      icon={<TwoPeopleIcon width={50} height={50}/>}
      dashboardLoading={dashboardLoading}
    />;

  let HouseholdsBox =
    <SingleMetricCard
      boxLabel={'Households'}
      boxData={box2Data ? box2Data + ' households' : 'No Data'}
      footnoteText={box2Data ? `Based on ${data.years[1]}` : 'No Data'}  
      icon={<TwoHousesIcon width={50} height={50}/>}
      dashboardLoading={dashboardLoading}
    />

  let DensityBox = 
    <SingleMetricCard
      boxLabel={'Density'}
      boxData={box3Data ? box3Data + ' persons/sq.km' : 'No Data'}
      footnoteText={false ? `Based on ${data.years[1]}` : 'No Data'}  
      icon={<PersonInBoxIcon width={50} height={50}/>}
      dashboardLoading={dashboardLoading}
    />

  switch (dashboard) {
    
    /* -------------------------------------------------------------------------- */
    /*                             OVERVIEW DASHBOARD                             */
    /* -------------------------------------------------------------------------- */
    
    case dashboardObject['Overview'].name:

      filterText = false;

      chartsToRender = 
        <GeneralDashboardLayout
          demoBox1={ PopulationBox }
          demoBox2={ HouseholdsBox }
          demoBox3={ DensityBox }
          
          map={ 
            <GeneralDashboardCard>
              <Map 
                name={formattedMunicipalName[0]}
                theme={theme}
                error={dashboardError}
              />
            </GeneralDashboardCard> 
          }

          chart1={
            // Annual Operating Expenditure
            <GeneralDashboardCard>
              <BarChart
                chartTitle = { chart1Title + multiYear }
                labelArray = { chart1Label }
                dataArray = { chart1Data }
                dashboardLoading = { dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Annual Tax Revenue
            <GeneralDashboardCard>
              <LineChart
                chartTitle={ chart2Title + multiYear }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart3={
            // Weighted and Discounted Assessment by Property Type
            <GeneralDashboardCard>
              <DoughnutChart
                chartTitle={ chart3Title + singleYear }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart4={
            // Net Debt vs Debt Limit
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart4Title + singleYear }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          /* ---------------------- Overview Dashboard Footnotes ---------------------- */

          mapFootnote = {
            <Footnote
              footnote={footnote}
              footnoteObject={mapFootnote}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;
    
    /* -------------------------------------------------------------------------- */
    /*                            EXPENDITURE DASHBOARD                           */
    /* -------------------------------------------------------------------------- */
    
    case dashboardObject['Expenditure'].name:

      filterText = false;

      chartsToRender = 
        <GeneralDashboardLayout
          demoBox1={ PopulationBox }
          demoBox2={ HouseholdsBox }
          demoBox3={ DensityBox }
          
          map={ 
            <GeneralDashboardCard>
              <Map 
                name={formattedMunicipalName[0]}
                theme={theme}
                error={dashboardError}
              />
            </GeneralDashboardCard> 
          }
          
          chart1={
            // Annual Operating Expenditure
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart1Title + multiYear }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
          // Operating Expenditure by Type
          <GeneralDashboardCard>
            <DoughnutChart
              chartTitle={ chart2Title + singleYear  }
              labelArray={ chart2Label }
              dataArray={ chart2Data }
              dashboardLoading={ dashboardLoading }
              theme={ theme }
            />
          </GeneralDashboardCard>
          }
          
          chart3={
            // Operating Expenditure by Service Area
          <GeneralDashboardCard>
            <DoughnutChart
              chartTitle={ chart3Title + singleYear  }
              labelArray={ chart3Label }
              dataArray={ chart3Data }
              dashboardLoading={ dashboardLoading }
              theme={ theme }
            />
          </GeneralDashboardCard>
          }
          
          chart4={
            // Outstanding Debt by Service Area
            <GeneralDashboardCard>
              <DoughnutChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          /* --------------------- Expenditure Dashboard Footnotes -------------------- */

          mapFootnote = {
            <Footnote
              footnote={footnote}
              footnoteObject={mapFootnote}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                              REVENUE DASHBOARD                             */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Revenue'].name:
      
      filterText = false;
      
      chartsToRender = 
        <GeneralDashboardLayout
          demoBox1={ PopulationBox }
          demoBox2={ HouseholdsBox }
          demoBox3={ DensityBox }
          
          map={ 
            <GeneralDashboardCard>
              <Map 
                name={formattedMunicipalName[0]}
                theme={theme}
                error={dashboardError}
              />
            </GeneralDashboardCard> 
          }
          
          chart1={
            // Annual Tax Revenue
            <GeneralDashboardCard>
              <LineChart
                chartTitle={ chart1Title + multiYear }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Tax Revenue by Property Type
            <GeneralDashboardCard>
              <DoughnutChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
            }
          
          chart3={
            // Operating Related Revenue by Source
            <GeneralDashboardCard>
              <DoughnutChart
                chartTitle={ chart3Title + singleYear  }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
            }
          
          chart4={
            // Annual Development Charge Revenue
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart4Title + multiYear }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }

          /* ----------------------- Revenue Dashboard Footnotes ---------------------- */

          mapFootnote = {
            <Footnote
              footnote={footnote}
              footnoteObject={mapFootnote}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                              GROWTH DASHBOARD                              */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Growth'].name:
      
      filterText = false;
      
      chartsToRender = 
        <ThreeChartHorizontalDashboardLayout
          demoBox1={ PopulationBox }
          demoBox2={ HouseholdsBox }
          demoBox3={ DensityBox }
          
          map={ 
            <GeneralDashboardCard>
              <Map 
                name={formattedMunicipalName[0]}
                theme={theme}
                error={dashboardError}
              />
            </GeneralDashboardCard> 
          }
          
          chart1={
            // Census Population
            <GeneralDashboardCard>
              <LineChart
                chartTitle={ chart1Title + multiYear }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                symbol={'none'}
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Households
            <GeneralDashboardCard>
              <LineChart
                chartTitle={ chart2Title + multiYear }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                symbol={'none'}
              />
            </GeneralDashboardCard>
            }

          /* ----------------------- Growth Dashboard Footnotes ----------------------- */
          
          mapFootnote = {
            <Footnote
              footnote={footnote}
              footnoteObject={mapFootnote}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                            ASSESSMENT DASHBOARD                            */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Assessment'].name:

      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          chart1={
            // Weighted and Discounted Taxable Assessment per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Weighted and Discounted Taxable Assessment by Property Type
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sortLabel='Residential'
              />
            </GeneralDashboardCard>
          }
          
          chart3={
            // Taxable Assessment per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart3Title + singleYear  }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart4={
            // Taxable Assessment by Property Type
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sortLabel='Residential'
              />
            </GeneralDashboardCard>
          }
                    
          /* --------------------- Assessment Dashboard Footnotes --------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                            DEBT LEVELS DASHBOARD                           */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Debt Levels'].name:

      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          chart1={
            // Net Debt Payments per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Net Debt vs Net Revenue (Debt Levels)
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                symbol={'percent'}
                decimal={3}
              />
            </GeneralDashboardCard>
          }
          
          chart3={
            // Total Outstanding Debt per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart3Title + singleYear  }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }

          chart4={
            // Outstanding Debt by Service Area
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
                    
          /* --------------------- Debt Levels Dashboard Footnotes -------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                             RESERVES DASHBOARD                             */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Reserves'].name:
      
      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          
          chart1={
            // Total Reserves per Capita
            <GeneralDashboardCard>
              <StackedBarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sort={ true }
              />
            </GeneralDashboardCard>
          }

          chart2={
            // Reserves for Tax Funded Services per Capita (excluding Water, Wastewater and Stormwater)
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }

          chart3={
            // Water and Wastewater Rate Funded Reserves per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart3Title + singleYear  }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }

          chart4={
            // Stormwater Reserves per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart5={
            // Reserve to Debt Ratio
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart5Title + singleYear  }
                labelArray={ chart5Label }
                dataArray={ chart5Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                decimal={2}
              />
            </GeneralDashboardCard>
          }        
                    
          /* ---------------------- Reserves Dashboard Footnotes ---------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote5={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote5}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                             TAXATION DASHBOARD                             */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Taxation'].name:

      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          chart1={
            // Tax Revenue per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Tax Revenue by Property Type
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sortLabel='Residential'
              />
            </GeneralDashboardCard>
          }
                              
          /* ---------------------- Taxation Dashboard Footnotes ---------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;
      
      /* -------------------------------------------------------------------------- */
      /*                       OPERATING EXPENDITURE DASHBOARD                      */
      /* -------------------------------------------------------------------------- */

      case dashboardObject['Operating Expenditure'].name:

        // Must be set for dashboards with filter      
        filterText = createFilterTextObject('Operating Expenditure', data);
      
        // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
        formattedMunicipalName = municipalityArray.map((object) => {
          const municipalDataComplete = object.allDataAvailable; 

          return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
        });

        chartsToRender =
          <FiveChartStackedLayout
            chart1={
              // Operating Expenditure per Capita
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart1Title + singleYear  }
                  labelArray={ chart1Label }
                  dataArray={ chart1Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                />
              </GeneralDashboardCard>
            }

            chart2={
              // Operating Expenditure per Capita by Service Area
              <GeneralDashboardCard>
                <StackedBarChart
                  chartTitle={ chart2Title + singleYear  }
                  labelArray={ chart2Label }
                  dataArray={ chart2Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  sort={ true }
                />
              </GeneralDashboardCard>
            }

            chart3={
              // Operating Expenditure
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart3Title + singleYear  }
                  labelArray={ chart3Label }
                  dataArray={ chart3Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                />
              </GeneralDashboardCard>
            }

            chart4={
              // Operating Expenditure by Service Area
              <GeneralDashboardCard>
                <StackedBarChart
                  chartTitle={ chart4Title + singleYear  }
                  labelArray={ chart4Label }
                  dataArray={ chart4Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  sort={ true }
                />
              </GeneralDashboardCard>
            }            
                                
            /* ---------------- Operating Expenditure Dashboard Footnotes --------------- */

            chartFootnote1={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote1}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote2={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote2}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote3={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote3}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote4={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote4}
                dashboardLoading={dashboardLoading}
              />
            }

          />
        break;


      /* -------------------------------------------------------------------------- */
      /*                             USER FEES DASHBOARD                            */
      /* -------------------------------------------------------------------------- */


      case dashboardObject['User Fees'].name:

        // Must be set for dashboards with filter      
        filterText = createFilterTextObject('User Fees', data);
      
        // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
        formattedMunicipalName = municipalityArray.map((object) => {
          const municipalDataComplete = object.allDataAvailable; 

          return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
        });

        chartsToRender =
          <FiveChartStackedLayout
            chart1={
              // User Fee Recovery Ratio
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart1Title + singleYear }
                  labelArray={ chart1Label }
                  dataArray={ chart1Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  symbol={'percent'}
                  decimal={3}
                />
              </GeneralDashboardCard>
            }
            
            chart2={
              // User Fee Revenue per Capita
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart2Title + singleYear }
                  labelArray={ chart2Label }
                  dataArray={ chart2Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  decimal={2}
                />
              </GeneralDashboardCard>
            } 

            chart3={
              // User Fee Revenue
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart3Title + singleYear }
                  labelArray={ chart3Label }
                  dataArray={ chart3Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                />
              </GeneralDashboardCard>
            }
                                
            /* ---------------------- User Fees Dashboard Footnotes --------------------- */

            chartFootnote1={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote1}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote2={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote2}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote3={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote3}
                dashboardLoading={dashboardLoading}
              />
            }
          
          
          />
        break;
        
      /* -------------------------------------------------------------------------- */
      /*                             TAX BILL DASHBOARD                             */
      /* -------------------------------------------------------------------------- */

      case dashboardObject['Tax Bill'].name:

        // Must be set for dashboards with filter      
        filterText = false; // createFilterTextObject('Tax Bill', data);
      
        // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
        formattedMunicipalName = municipalityArray.map((object) => {
          const municipalDataComplete = object.allDataAvailable; 

          return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
        });

        chartsToRender =
          <FiveChartStackedLayout
            chart1={
              // Tax Bill per $100,000 of Assessment
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart1Title + singleYear  }
                  labelArray={ chart1Label }
                  dataArray={ chart1Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                />
              </GeneralDashboardCard>
            }

            chart2={
              // Tax Bill per $100,000 of Assessment by Component
              <GeneralDashboardCard>
                <StackedBarChart
                  chartTitle={ chart2Title + singleYear  }
                  labelArray={ chart2Label }
                  dataArray={ chart2Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  sort={ true }
                />
              </GeneralDashboardCard>
            }

            chart3={
              // Average Tax Bill per Single Detached Unit
              <GeneralDashboardCard className={'d-none'}>
                <BarChart
                  chartTitle={ chart3Title + singleYear  }
                  labelArray={ chart3Label }
                  dataArray={ chart3Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                />
              </GeneralDashboardCard>
            }

            chart4={
              // Average Tax Bill per Single Detached Unit by Component
              <GeneralDashboardCard className={'d-none'}>
                <StackedBarChart
                  chartTitle={ chart4Title + singleYear  }
                  labelArray={ chart4Label }
                  dataArray={ chart4Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  sort={ true }
                />
              </GeneralDashboardCard>
            }            
                                
            /* ---------------------- User Fees Dashboard Footnotes --------------------- */

            chartFootnote1={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote1}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote2={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote2}
                dashboardLoading={dashboardLoading}
              />
            }

            // chartFootnote3={
            //   <Footnote
            //     footnote={footnote}
            //     footnoteObject={chartFootnote3}
            //     dashboardLoading={dashboardLoading}
            //   />
            // }

            // chartFootnote4={
            //   <Footnote
            //     footnote={footnote}
            //     footnoteObject={chartFootnote4}
            //     dashboardLoading={dashboardLoading}
            //   />
            // }                                           
          
          />
        break;

    /* -------------------------------------------------------------------------- */
    /*                             WORKFORCE DASHBOARD                            */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Workforce'].name:

      // Must be set for dashboards with filter      
      filterText = createFilterTextObject('Workforce', data);
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout        
          
          chart1={
            // Funded Positions per Thousand Population
            <GeneralDashboardCard>
              {<BarChart
                chartTitle={ chart1Title + singleYear }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                symbol={'none'}
                decimal={1}
                filterText={filterText}
              />}
            </GeneralDashboardCard>
          }

          chart2={
            // Funded Positions per Thousand Population by Service Area
            <GeneralDashboardCard>
              <StackedBarChart
                chartTitle={ chart2Title }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sort={ true }
                symbol={'none'}
                decimal={1}
              />
            </GeneralDashboardCard>
          }

          chart3={
            // Funded Positions
            <GeneralDashboardCard>
              {<BarChart
                chartTitle={ chart3Title + singleYear }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                symbol={'none'}
              />}
            </GeneralDashboardCard>
          }
          
          chart4={
            // Funded Positions by Service Area
            <GeneralDashboardCard>
              <StackedBarChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sort={ true }
                symbol={'none'}
              />
            </GeneralDashboardCard>
          }
                              
          /* ---------------------- Workforce Dashboard Footnotes --------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }

          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }

          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }

          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;


    /* -------------------------------------------------------------------------- */
    /*                       CONSTRUCTION ACTIVITY DASHBOARD                      */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Construction Activity'].name:

      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout        
                    
          chart1={
            // Value of Building Permits
            <GeneralDashboardCard>
              <StackedBarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sort={ true }
              />
            </GeneralDashboardCard>
          }
                              
          /* ---------------- Construction Activity Dashboard Footnotes --------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                         AFFORDABLE UNITS DASHBOARD                         */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Affordable Units'].name:

      // Must be set to false if not filter on dashboard   
      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout        
          
          chart1={
            // Average Price
            <GeneralDashboardCard>
              {<BarChart
                chartTitle={ chart1Title }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />}
            </GeneralDashboardCard>
          }

          chart2={
            // Average Price
            <GeneralDashboardCard>
              {<BarChart
                chartTitle={ chart2Title }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />}
            </GeneralDashboardCard>
          }

          chart3={
            // Average Price
            <GeneralDashboardCard>
              {<BarChart
                chartTitle={ chart3Title }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />}
            </GeneralDashboardCard>
          }

          chart4={
            // Average Price
            <GeneralDashboardCard>
              {<BarChart
                chartTitle={ chart4Title }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />}
            </GeneralDashboardCard>
          }
                                        
          /* ------------------ Affordable Units Dashboard Footnotes ------------------ */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }

          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }

          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }

          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                           UTILITY RATES DASHBOARD                          */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Utility Rates'].name:

      // Must be set for dashboards with filter      
      filterText = false; // createFilterTextObject('Utility Rates', data);
    
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout

          chart1={
            // Typical Water and Wastewater Bill (200 m3 per Year)
            <GeneralDashboardCard>
              <StackedBarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sort={ true }
              />
            </GeneralDashboardCard>
          }
                
          /* -------------------- Utility Rates Dashboard Footnotes ------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }                                        
        
        />
      break;


    /* -------------------------------------------------------------------------- */
    /*                         REGION ASSESSMENT DASHBOARD                        */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Region Assessment'].name:

      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          chart1={
            // Weighted and Discounted Taxable Assessment per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Weighted and Discounted Taxable Assessment by Property Type
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sortLabel='Residential'
              />
            </GeneralDashboardCard>
          }
          
          chart3={
            // Taxable Assessment per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart3Title + singleYear  }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart4={
            // Taxable Assessment by Property Type
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sortLabel='Residential'
              />
            </GeneralDashboardCard>
          }
                    
          /* ------------------ Region Assessment Dashboard Footnotes ----------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                        REGION DEBT LEVELS DASHBOARD                        */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Region Debt Levels'].name:

      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          chart1={
            // Net Debt Payments per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Net Debt vs Net Revenue (Debt Levels)
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                symbol={'percent'}
                decimal={3}
              />
            </GeneralDashboardCard>
          }
          
          chart3={
            // Total Outstanding Debt per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart3Title + singleYear  }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }

          chart4={
            // Outstanding Debt by Service Area
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }                    

          /* ----------------- Region Debt Levels Dashboard Footnotes ----------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                          REGION RESERVES DASHBOARD                         */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Region Reserves'].name:
      
      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          
          chart1={
            // Total Reserves per Capita
            <GeneralDashboardCard>
              <StackedBarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sort={ true }
              />
            </GeneralDashboardCard>
          }

          chart2={
            // Reserves for Tax Funded Services per Capita (excluding Water, Wastewater and Stormwater)
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }

          chart3={
            // Water and Wastewater Rate Funded Reserves per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart3Title + singleYear  }
                labelArray={ chart3Label }
                dataArray={ chart3Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }

          chart4={
            // Stormwater Reserves per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart4Title + singleYear  }
                labelArray={ chart4Label }
                dataArray={ chart4Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart5={
            // Reserve to Debt Ratio
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart5Title + singleYear  }
                labelArray={ chart5Label }
                dataArray={ chart5Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                decimal={2}
              />
            </GeneralDashboardCard>
          }        
                    
          /* ---------------------- Reserves Dashboard Footnotes ---------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote3={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote3}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote4={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote4}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote5={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote5}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

    /* -------------------------------------------------------------------------- */
    /*                          REGION TAXATION DASHBOARD                         */
    /* -------------------------------------------------------------------------- */

    case dashboardObject['Region Taxation'].name:

      filterText = false;
      
      // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
      formattedMunicipalName = municipalityArray.map((object) => {
        const municipalDataComplete = object.allDataAvailable; 

        return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
      });

      chartsToRender =
        <FiveChartStackedLayout
          chart1={
            // Tax Revenue per Capita
            <GeneralDashboardCard>
              <BarChart
                chartTitle={ chart1Title + singleYear  }
                labelArray={ chart1Label }
                dataArray={ chart1Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
              />
            </GeneralDashboardCard>
          }
          
          chart2={
            // Tax Revenue by Property Type
            <GeneralDashboardCard>
              <PercentStackedBarChart
                chartTitle={ chart2Title + singleYear  }
                labelArray={ chart2Label }
                dataArray={ chart2Data }
                dashboardLoading={ dashboardLoading }
                theme={ theme }
                sortLabel='Residential'
              />
            </GeneralDashboardCard>
          }
                              
          /* ---------------------- Taxation Dashboard Footnotes ---------------------- */

          chartFootnote1={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote1}
              dashboardLoading={dashboardLoading}
            />
          }
          chartFootnote2={
            <Footnote
              footnote={footnote}
              footnoteObject={chartFootnote2}
              dashboardLoading={dashboardLoading}
            />
          }
        
        />
      break;

      /* -------------------------------------------------------------------------- */
      /*                   REGION OPERATING EXPENDITURE DASHBOARD                   */
      /* -------------------------------------------------------------------------- */

      case dashboardObject['Region Operating Expenditure'].name:

        // Must be set for dashboards with filter      
        filterText = createFilterTextObject('Region Operating Expenditure', data);
      
        // Check if data is missing by comparing the names in formattedMunicipalName array and the data in chart1 (used as the stand-in).
        formattedMunicipalName = municipalityArray.map((object) => {
          const municipalDataComplete = object.allDataAvailable; 

          return municipalDataComplete ? object.name : object.name + ' (Missing Data)';
        });

        chartsToRender =
          <FiveChartStackedLayout
            chart1={
              // Operating Expenditure per Capita
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart1Title + singleYear  }
                  labelArray={ chart1Label }
                  dataArray={ chart1Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                />
              </GeneralDashboardCard>
            }

            chart2={
              // Operating Expenditure per Capita by Service Area
              <GeneralDashboardCard>
                <StackedBarChart
                  chartTitle={ chart2Title + singleYear  }
                  labelArray={ chart2Label }
                  dataArray={ chart2Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  sort={ true }
                />
              </GeneralDashboardCard>
            }

            chart3={
              // Operating Expenditure
              <GeneralDashboardCard>
                <BarChart
                  chartTitle={ chart3Title + singleYear  }
                  labelArray={ chart3Label }
                  dataArray={ chart3Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                />
              </GeneralDashboardCard>
            }

            chart4={
              // Operating Expenditure by Service Area
              <GeneralDashboardCard>
                <StackedBarChart
                  chartTitle={ chart4Title + singleYear  }
                  labelArray={ chart4Label }
                  dataArray={ chart4Data }
                  dashboardLoading={ dashboardLoading }
                  theme={ theme }
                  sort={ true }
                />
              </GeneralDashboardCard>
            }            
                                
            /* ---------------- Operating Expenditure Dashboard Footnotes --------------- */

            chartFootnote1={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote1}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote2={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote2}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote3={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote3}
                dashboardLoading={dashboardLoading}
              />
            }

            chartFootnote4={
              <Footnote
                footnote={footnote}
                footnoteObject={chartFootnote4}
                dashboardLoading={dashboardLoading}
              />
            }

          />
        break;

    /* -------------------- Insert Dashboards Above This Line ------------------- */
    default:
      chartsToRender = <p>No dashboards loaded.</p>;
      break;
  }
  
  return (
    <>
      {dashboardLoading ? <LoadingBar progress={progress}/> : ''}
      <Row className="pt-3 g-3">
        <Col>
          <TitleCard
            dashboard={dashboard}
            formattedMunicipalName={formattedMunicipalName}
            dataStatus={dataStatus}
            dashboardLoading={dashboardLoading}
            constant={constant}
            dashboardError={dashboardError}
            dashboardErrorMessage={dashboardErrorMessage}
            filterText={filterText}
          />
          <GeneralFootnote
            text={(
              <>
                <span className="fw-bold">Dashboard Description: </span>{`${dashboardObject[dashboard].description} ${dashboardObject[dashboard].experimental ? 'This dashboard is experimental and is subject to change.' : ''}`}
              </>
            )}
            footnote={footnote}         
          />
        </Col>
      </Row>

      { chartsToRender }
      
      {/* copyright */}
      <Row className="pt-3 g-3 mt-auto mb-3 mb-md-0">
        <Col>
          <h6>Powered by:</h6>          
          <HemsonLogo width={110}/>          
          <p className="mb-5 mb-lg-4 mt-1"><small>© Hemson Consulting 2024</small></p>
        </Col>
      </Row>
    </>
  );
}

export default Dashboards;